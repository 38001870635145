import { IGenuinePart } from '@/types/shared/parts';

const getMonth = (month: number) => {
  switch (month) {
    case 0:
      return 'ม.ค.';
    case 1:
      return 'ก.พ.';
    case 2:
      return 'มี.ค.';
    case 3:
      return 'เม.ย.';
    case 4:
      return 'พ.ค.';
    case 5:
      return 'มิ.ย.';
    case 6:
      return 'ก.ค.';
    case 7:
      return 'ส.ค.';
    case 8:
      return 'ก.ย.';
    case 9:
      return 'ต.ค.';
    case 10:
      return 'พ.ย.';
    case 11:
      return 'ธ.ค.';
  }
};

const getMonthFull = (month: number) => {
  switch (month) {
    case 0:
      return 'มกราคม';
    case 1:
      return 'กุมภาพันธ์';
    case 2:
      return 'มีนาคม';
    case 3:
      return 'เมษายน';
    case 4:
      return 'พฤษภาคม';
    case 5:
      return 'มิถุนายน';
    case 6:
      return 'กรกฎาคม';
    case 7:
      return 'สิงหาคม';
    case 8:
      return 'กันยายน';
    case 9:
      return 'ตุลาคม';
    case 10:
      return 'พฤศจิกายน';
    case 11:
      return 'ธันวาคม';
  }
};

export const formatDate = (date: string | number | Date) => {
  const _date = new Date(date);

  return `${_date.getUTCDate()} ${getMonth(_date.getUTCMonth())} ${
    _date.getUTCFullYear() + 543
  }`;
};

export const formatDateFull = (date: string | number | Date) => {
  const _date = new Date(date);

  return `${_date.getUTCDate()} ${getMonthFull(_date.getUTCMonth())} ${
    _date.getUTCFullYear() + 543
  }`;
};

export const formatTel = (tel: string, stripDash = false): string => {
  let formattedTel = tel;
  if (tel[0] !== '0') {
    formattedTel = `0${tel}`;
  }

  if (stripDash) {
    formattedTel = formattedTel.split('-')[0];
  }

  return formattedTel;
};

export const youtubeURL = (id: string) => {
  if (id === '') return '';
  return `https://www.youtube.com/embed/${id}/?autoplay=1&rel=0&modestbranding=1`;
};

export const changeDashToUnderscore = (text: string): string => {
  return text.replace(/-/g, '_');
};

export const removeSpan = (text: string): string => {
  return text.replace(/<\/?span[^>]*>/g, '');
};

export const removeP = (text: string): string => {
  return text.replace(/<\/?p[^>]*>/g, '');
};

export const removeHTMLTag = (text = ''): string => {
  return text.replace(/<\/?[^>]+(>|$)/g, '');
};

export const removeEmptyTags = (text: string) => {
  // Remove <p> tags
  const withoutEmptyPTags = text.replace(/<p\b[^>]*>\s*<\/p>/gi, '');

  // Remove <span> tags
  const withoutEmptySpanTags = withoutEmptyPTags.replace(
    /<span\b[^>]*>\s*<\/span>/gi,
    ''
  );
  // Remove <br /> tags
  const withoutBrTags = withoutEmptySpanTags.replace(/<br\s*\/?>/gi, '');

  return withoutBrTags;
};

// Remove empty <p> tags
export const removeEmptyParagraphTags = (text: string) => {
  const withoutEmptyPTags = text.replace(/<p\b[^>]*>\s*<\/p>/gi, '');

  return withoutEmptyPTags;
};

export const removeBrTags = (text: string) => {
  // Remove <br /> tags
  const withoutBrTags = text.replace(/<br\s*\/?>/gi, '');

  return withoutBrTags;
};

export const formatPrice = (val: string | number, unit = true) => {
  const num: number = +val || 0;
  return (
    (unit ? 'THB ' : '') +
    num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  );
};

export const removeHash = (text: string) => {
  return text.replace('#', '');
};

export const removeWhitespace = (text: string) => {
  return text.replace(/\s/g, '');
};

export const camelCase = (text: string) => {
  return text.replace(/-([a-z])/g, (g) => {
    return g[1].toUpperCase();
  });
};

export const formatTextToLowerCaseWithUnderscores = (text: string) => {
  // Convert text to lowercase
  const lowercaseText = text.toLowerCase();

  // Replace whitespace with underscores
  const processedText = lowercaseText.replace(/\s/g, '_');

  return processedText;
};

export const formatTextToLowerCaseWithDash = (text: string) => {
  // Convert text to lowercase
  const lowercaseText = text.toLowerCase();

  // Replace whitespace with underscores
  const processedText = lowercaseText.replace(/\s/g, '-');

  return processedText;
};

export const removeAsterisk = (text: string) => {
  return text.replace(/[*]/g, '');
};

export const formatNumber = (val: string, decimal = 0) => {
  return Number(val) === 0 ? '-' : Number(val).toFixed(decimal);
};

export const cleanUrl = (val: string) => {
  return val.replace('//', '/');
};

export const removeDuplicateDot = (value: string) => {
  while (value.split('.').length > 2) {
    const lastIndex = value.lastIndexOf('.');
    value = `${value.substring(0, lastIndex)}${value.substring(lastIndex + 1)}`;
  }
  return value;
};

export const limitFloat = (value: string, decimalNumber: number) => {
  if (
    value.split('.').length == 2 &&
    value.split('.')[1].length > decimalNumber
  ) {
    let floatValue = value.split('.')[1];
    floatValue = floatValue.substr(0, decimalNumber);
    value = `${value.split('.')[0]}.${floatValue}`;
  }
  return value;
};

export const limit2Float = (value: string) => {
  if (value.split('.').length == 2 && value.split('.')[1].length > 2) {
    let floatValue = value.split('.')[1];
    floatValue = floatValue.substr(0, 2);
    value = `${value.split('.')[0]}.${floatValue}`;
  }
  return value;
};

export const getValidatedPaymentValue = (value: string, price?: string) => {
  let result = value;
  result = value.replace(/[^0-9]/, '');
  result = value.replaceAll(',', '');
  if (price && parseFloat(result) > parseFloat(price)) {
    return price.toString();
  }
  return result;
};

export const getPartPrefix = (part: IGenuinePart) => {
  let prefix = part.isIsuzuGenuinePart
    ? 'อะไหล่แท้<span>อีซูซุ</span>'
    : part.isTripetchGenuinePart
    ? 'อะไหล่แท้<span>ตรีเพชร</span>'
    : part.isBvpPart
    ? 'อีซูซุเบสท์<span>แวรลู</span>'
    : part.title && part.title.length > 0
    ? part.title
    : '';

  prefix = part.isStartPrice ? `${prefix}` : prefix;

  return prefix;
};

export const normaliseToArray = (value: string | string[]): string[] => {
  if (typeof value === 'string') {
    return [value];
  } else if (Array.isArray(value)) {
    return value;
  }
  return [];
};

export const isValidDate = (d: Date) => {
  return d instanceof Date && !Number.isNaN(+d);
};

export const formatThaiDateTime = (
  date: Date | string | number | null | undefined
) => {
  if (date === null || date === undefined) return '';

  const parsedDate = new Date(date);
  if (!isValidDate(parsedDate)) return '';

  const parsedDateTimeZoneString = parsedDate.toLocaleString('en', {
    timeZone: 'Asia/Bangkok',
    hour12: false,
  });
  const dateTimeZone = new Date(parsedDateTimeZoneString);
  const formattedDate = dateTimeZone.toISOString().slice(0, 10);
  let formattedTime = '';
  // Need to check in case of time format return 'NaN' or 'undefined'
  if (
    !Number.isNaN(dateTimeZone.getHours()) &&
    dateTimeZone.getHours() !== undefined
  ) {
    const hours = `0${dateTimeZone.getHours()}`.slice(-2);
    const minutes = `0${dateTimeZone.getMinutes()}`.slice(-2);
    const seconds = `0${dateTimeZone.getSeconds()}`.slice(-2);
    formattedTime = `${hours}:${minutes}:${seconds}`;
  } else {
    formattedTime = dateTimeZone.toISOString().slice(11, 19);
  }
  return `${formattedDate} ${formattedTime}`;
};
